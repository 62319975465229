/* eslint-disable no-useless-escape */
// @flow

export type TGTLAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    source: string,
    uncover_order: Array<string>,
};

type TGTLAnswers = {
    [string]: TGTLAnswer
};

export const answers: TGTLAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Mastercard"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/MA-c8ce4564.png?t=1720244492',
        uncover_order: [2, 5, 4, 7],
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["Facebook", "Meta"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/FB-2d2223ad.png?t=1633076955&download=true',
        uncover_order: [0, 2, 4, 6],
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["McDonald's"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/MCD-4fd8e96f.png?t=1647435923',
        uncover_order: [7, 5, 1, 0],
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["Shell"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/SHEL-a71e2d12.png?t=1633506726',
        uncover_order: [2, 8, 4, 3],
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Coca-Cola"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/KO-b23a2a5e.png?t=1720244492',
        uncover_order: [8, 0, 4, 5],
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["LinkedIn"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/linkedin-2c3012a9.png?t=1720244494',
        uncover_order: [2, 6, 4, 8],
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["Opera"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/OPRA-305c175c.png?t=1720244493',
        uncover_order: [6, 5, 0, 1],
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["BMW"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/BMW.DE-56e4d00d.png?t=1720244491',
        uncover_order: [6, 8, 4, 2],
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Wells Fargo"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/WFC-a4743c33.png?t=1720244494',
        uncover_order: [6, 2, 4, 5],
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["Rolls-Royce Holdings", "Rolls-Royce"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/RR.L-b61419e9.png?t=1720244493',
        uncover_order: [2, 7, 0, 4],
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["LG Energy Solution", "LG", "LG Electronics"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/373220.KS-93d78350.png?t=1720244490',
        uncover_order: [2, 6, 0, 4],
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["Netflix"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/NFLX-7466b597.png?t=1720244493',
        uncover_order: [6, 5, 4, 2],
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["Procter & Gamble"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/PG-b828a7df.png?t=1720244493',
        uncover_order: [7, 0, 3, 5],
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["Domino's Pizza"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/DPZ-9c373038.png?t=1720244491',
        uncover_order: [8, 1, 3, 4],
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["Dell"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/DELL-f7f7f0be.png?t=1720244491',
        uncover_order: [6, 1, 2, 5],
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Jollibee"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/JBFCF_BIG-6f9dd9a2.png?t=1720244492',
        uncover_order: [1, 6, 2, 8],
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["Yelp"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/YELP-d704c977.png?t=1720244494',
        uncover_order: [2, 6, 4, 3],
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["Salesforce"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/CRM-74b365dd.png?t=1720244491',
        uncover_order: [8, 7, 3, 5],
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["General Electric", "GE"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/GE-0f5d8584.png?t=1720244492',
        uncover_order: [8, 1, 4, 7],
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["PayPal"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/PYPL-3570673e.png?t=1720244493',
        uncover_order: [6, 3, 4, 2],
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["Unilever"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/UL-1dae83e7.png?t=1720244494',
        uncover_order: [8, 1, 3, 4],
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["ING"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ING-8c32bac2.png?t=1720244492',
        uncover_order: [2, 3, 8, 0],
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["Zoom"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ZM-b326e02f.png?t=1720244494',
        uncover_order: [6, 0, 2, 3],
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["eBay"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/EBAY-a442b3a2.png?t=1720244491',
        uncover_order: [1, 7, 5, 4],
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["Walmart"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/WMT-0d8ecd74.png?t=1720244494',
        uncover_order: [6, 0, 4, 2],
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["Alibaba"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/BABA-2884ac04.png?t=1720244490',
        uncover_order: [0, 8, 4, 2],
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["T-Mobile"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/TMUS-c281226a.png?t=1720244494',
        uncover_order: [6, 5, 4, 2],
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["Adobe"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ADBE-fb158b30.png?t=1720244490',
        uncover_order: [5, 6, 4, 1],
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["Verizon"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/VZ-13e34d8e.png?t=1720244494',
        uncover_order: [1, 5, 4, 3],
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["Tesla"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/TSLA-6da550e5.png?t=1722952499',
        uncover_order: [3, 5, 6, 8],
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["Globe Telecom, Inc.", "Globe Telecom"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/GTMEY-a1d3b2cf.png?t=1720244492',
        uncover_order: [3, 8, 5, 4],
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["Levi Strauss", "Levi's"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/LEVI-e8800e7f.png?t=1720244492',
        uncover_order: [6, 7, 8, 0],
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Qualcomm"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/QCOM-515e1eda.png?t=1720244493',
        uncover_order: [6, 4, 1, 8],
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["Capcom"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/9697.T-72f98d81.png?t=1720244490',
        uncover_order: [4, 5, 6, 0],
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["Western Union"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/WU-1a8f4ddc.png?t=1720244494',
        uncover_order: [6, 8, 7, 3],
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["Nestl\u00e9", "Nestle"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/NESN.SW-a5b680cf.png?t=1720244493',
        uncover_order: [6, 8, 2, 5],
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["FedEx"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/FDX-35c19ad4.png?t=1720244491',
        uncover_order: [0, 3, 6, 1],
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["Kellogg's", "Kellog"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/K-b27c0809.png?t=1720244492',
        uncover_order: [8, 0, 5, 1],
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Visa"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/V-05214186.png?t=1720244494',
        uncover_order: [6, 4, 3, 2],
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["DHL Group", "DHL"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/DHL.DE-8c515c8f.png?t=1720244491',
        uncover_order: [6, 7, 2, 1],
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["Xiaomi"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/XIACF-9058dc7f.png?t=1720244494',
        uncover_order: [1, 7, 5, 3],
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["El Pollo Loco"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/LOCO-8a6d92b6.png?t=1720244492',
        uncover_order: [4, 7, 5, 3],
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["Bandai Namco", "Bandai"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/7832.T-afbe2170.png?t=1720244490',
        uncover_order: [2, 1, 0, 3],
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["Golden State Warriors"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2018/03/Golden-State-Warriors-logo-700x394.png',
        uncover_order: [0, 2, 5, 6],
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["Lawson"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/2651.T_BIG-70b1c1d5.png?t=1720244490',
        uncover_order: [0, 7, 3, 1],
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["Costco"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/COST-180a6d1f.png?t=1720244491',
        uncover_order: [7, 3, 0, 1],
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["Red Bull"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2021/04/Red-Bull-logo.png',
        uncover_order: [8, 7, 6, 0],
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["Ford"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2018/02/Ford-Logo-2003.png',
        uncover_order: [0, 2, 5, 6],
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["Kappa"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2020/03/Kappa-logo.png',
        uncover_order: [0, 2, 5, 6],
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["McDonald\u2019s"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2017/03/McDonalds-logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["American Express"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2016/10/American-Express-logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["Target"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2017/06/Target-Logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["Lipton"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2017/12/Lipton-logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["Yonex"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2024/02/Yonex-Logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["Forbes"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2021/02/Forbes-logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["Citi"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2021/05/Citi-logo.png',
        uncover_order: [0, 2, 5, 6],
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["Chevrolet"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2019/12/Chevrolet-logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["Aliexpress"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2020/07/AliExpress-Logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["CNN", "Cable News Network"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2016/11/CNN-logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["Skype"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2017/06/Skype-logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["IKEA"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2017/02/IKEA-logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["Java"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2020/09/Java-Logo.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["Milwaukee"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2020/09/Milwaukee-Logo.png',
        uncover_order: [0, 2, 5, 6],
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["Fila"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2021/05/Fila-logo.png',
        uncover_order: [0, 2, 5, 6],
    },
};
